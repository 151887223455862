<template>
  <a-modal
    title="裁剪封面图"
    :visible="visible"
    :maskClosable="false"
    :confirmLoading="confirmLoading"
    :width="1100"
    :footer="null"
    @cancel="cancelHandel"
  >
    <a-row>
      <a-col :xs="24" :md="12" :style="{ height: '350px' }">
        <vue-cropper
          ref="cropper"
          :img="options.img"
          :outputSize="options.size"
          :outputType="options.outputType"
          :fixed="options.fixed"
          :autoCrop="options.autoCrop"
          :fixedBox="options.fixedBox"
          :fixedNumber="options.fixedNumber"
          :centerBox="options.centerBox"
          @realTime="realTime"
        >
        </vue-cropper>
      </a-col>
      <a-col :xs="24" :md="12" :style="{ height: '350px' }">
        <div class="avatar-upload-preview">
          <img :src="previews.url" :style="previews.img" />
        </div>
      </a-col>
    </a-row>
    <br />
    <a-row>
      <a-col :lg="2" :md="2">
        <a-upload name="file" :beforeUpload="beforeUpload" :showUploadList="false">
          <a-button icon="upload">选择图片</a-button>
        </a-upload>
      </a-col>
      <a-col :lg="{ span: 1, offset: 2 }" :md="2">
        <a-button icon="plus" @click="changeScale(1)" />
      </a-col>
      <a-col :lg="{ span: 1, offset: 1 }" :md="2">
        <a-button icon="minus" @click="changeScale(-1)" />
      </a-col>
      <a-col :lg="{ span: 1, offset: 1 }" :md="2">
        <a-button icon="undo" @click="rotateLeft" />
      </a-col>
      <a-col :lg="{ span: 1, offset: 1 }" :md="2">
        <a-button icon="redo" @click="rotateRight" />
      </a-col>
      <a-col :lg="{ span: 2, offset: 6 }" :md="2">
        <a-button type="primary" @click="finish('blob')">保存</a-button>
      </a-col>
    </a-row>
  </a-modal>
</template>
<script>
import { uploadPublicReadFile } from '@/api/common/index'
import { uuid } from '@/utils/util.js'

export default {
  data() {
    return {
      visible: false,
      confirmLoading: false,
      fileList: [],
      uploading: false,
      uploadImgUrl: process.env.VUE_APP_BASE_API + '/common/upload',
      options: {
        // img: 'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',
        img: '',
        fixed: true,
        fixedBox: true,
        autoCrop: true,
        outputType: 'jpeg',
        size: 1,
        fixedNumber: [420, 248],
        centerBox: false
      },
      folder: 'official/article',
      previews: {}
    }
  },
  computed: {},
  methods: {
    edit(data) {
      this.visible = true
      this.options.img = data
      /* 获取原始头像 */
    },
    close() {
      this.visible = false
    },
    cancelHandel() {
      this.close()
    },
    changeScale(num) {
      num = num || 1
      this.$refs.cropper.changeScale(num)
    },
    rotateLeft() {
      this.$refs.cropper.rotateLeft()
    },
    rotateRight() {
      this.$refs.cropper.rotateRight()
    },
    beforeUpload(file) {
      const reader = new FileReader()
      // 把Array Buffer转化为blob 如果是base64不需要
      // 转化为base64
      reader.readAsDataURL(file)
      reader.onload = () => {
        this.options.img = reader.result
      }
      // 转化为blob
      // reader.readAsArrayBuffer(file)

      return false
    },
    // 上传图片（点击上传按钮）
    finish(type) {
      const _this = this
      const formData = new FormData()
      // 输出
      if (type === 'blob') {
        this.$refs.cropper.getCropBlob((data) => {
          console.log(data)
          const img = window.URL.createObjectURL(data)
          this.model = true
          this.modelSrc = img
          const file = new File([data], this.options.outputType, { type: data.type })
          formData.append('file', file)
          formData.append('fileName', uuid())
          formData.append('folder', this.folder)
          formData.append('type', 'aliyunoss')
          uploadPublicReadFile(formData).then((response) => {
            this.open = false
            _this.$message.success('上传成功')
            _this.$emit('ok', response.url, response.fileName)
            _this.visible = false
          })
        })
      } else {
        this.$refs.cropper.getCropData((data) => {
          this.model = true
          this.modelSrc = data
        })
      }
    },
    okHandel() {
      const vm = this

      vm.confirmLoading = true
      setTimeout(() => {
        vm.confirmLoading = false
        vm.close()
        vm.$message.success('上传封面成功')
      }, 2000)
    },

    realTime(data) {
      this.previews = data
    }
  }
}
</script>

<style lang="less" scoped>
.avatar-upload-preview {
  width: 420px;
  height: 248px;
  margin: 50px auto 0;
  box-shadow: 0 0 4px #ccc;
  overflow: hidden;

  img {
  }
}
</style>
