import request from '@/utils/request'

// 通用上传接口
export function commonUpload(data) {
  return request({
    url: '/common/upload',
    method: 'post',
    data: data
  })
}

// 通用上传接口,免鉴权
export function uploadPublicReadFile(data) {
  return request({
    url: '/common/uploadPublicReadFile',
    method: 'post',
    data: data
  })
}
