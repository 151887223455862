var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-modal",
    {
      attrs: {
        title: "裁剪封面图",
        visible: _vm.visible,
        maskClosable: false,
        confirmLoading: _vm.confirmLoading,
        width: 1100,
        footer: null,
      },
      on: { cancel: _vm.cancelHandel },
    },
    [
      _c(
        "a-row",
        [
          _c(
            "a-col",
            { style: { height: "350px" }, attrs: { xs: 24, md: 12 } },
            [
              _c("vue-cropper", {
                ref: "cropper",
                attrs: {
                  img: _vm.options.img,
                  outputSize: _vm.options.size,
                  outputType: _vm.options.outputType,
                  fixed: _vm.options.fixed,
                  autoCrop: _vm.options.autoCrop,
                  fixedBox: _vm.options.fixedBox,
                  fixedNumber: _vm.options.fixedNumber,
                  centerBox: _vm.options.centerBox,
                },
                on: { realTime: _vm.realTime },
              }),
            ],
            1
          ),
          _c(
            "a-col",
            { style: { height: "350px" }, attrs: { xs: 24, md: 12 } },
            [
              _c("div", { staticClass: "avatar-upload-preview" }, [
                _c("img", {
                  style: _vm.previews.img,
                  attrs: { src: _vm.previews.url },
                }),
              ]),
            ]
          ),
        ],
        1
      ),
      _c("br"),
      _c(
        "a-row",
        [
          _c(
            "a-col",
            { attrs: { lg: 2, md: 2 } },
            [
              _c(
                "a-upload",
                {
                  attrs: {
                    name: "file",
                    beforeUpload: _vm.beforeUpload,
                    showUploadList: false,
                  },
                },
                [
                  _c("a-button", { attrs: { icon: "upload" } }, [
                    _vm._v("选择图片"),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "a-col",
            { attrs: { lg: { span: 1, offset: 2 }, md: 2 } },
            [
              _c("a-button", {
                attrs: { icon: "plus" },
                on: {
                  click: function ($event) {
                    return _vm.changeScale(1)
                  },
                },
              }),
            ],
            1
          ),
          _c(
            "a-col",
            { attrs: { lg: { span: 1, offset: 1 }, md: 2 } },
            [
              _c("a-button", {
                attrs: { icon: "minus" },
                on: {
                  click: function ($event) {
                    return _vm.changeScale(-1)
                  },
                },
              }),
            ],
            1
          ),
          _c(
            "a-col",
            { attrs: { lg: { span: 1, offset: 1 }, md: 2 } },
            [
              _c("a-button", {
                attrs: { icon: "undo" },
                on: { click: _vm.rotateLeft },
              }),
            ],
            1
          ),
          _c(
            "a-col",
            { attrs: { lg: { span: 1, offset: 1 }, md: 2 } },
            [
              _c("a-button", {
                attrs: { icon: "redo" },
                on: { click: _vm.rotateRight },
              }),
            ],
            1
          ),
          _c(
            "a-col",
            { attrs: { lg: { span: 2, offset: 6 }, md: 2 } },
            [
              _c(
                "a-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.finish("blob")
                    },
                  },
                },
                [_vm._v("保存")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }